<script setup lang="ts">
import { tv } from "tailwind-variants"

defineProps<{
  error?: string
  status: "error" | "warning"
}>()

const classes = tv({
  base: "mt-1 flex items-center gap-2 font-semibold",
  variants: {
    status: {
      error: "text-red-700",
      warning: "text-status-warning",
    },
  },
})

const icons = {
  error: "mingcute:warning-fill",
  warning: "fa6-solid:triangle-exclamation",
}
</script>

<template>
  <p v-if="error" :class="classes({ status })">
    <Icon :name="icons[status]" class="flex-shrink-0" />{{ error }}
  </p>
</template>
