<script setup lang="ts">
import type { InputBoxProps } from "~/types/props"

import { tv } from "tailwind-variants"

const props = defineProps<InputBoxProps>()

const slots = useSlots()

const emit =
  defineEmits<(e: "update:modelValue", value: string | undefined) => void>()

function emitValue(value: string) {
  if (props.optional) {
    return emit("update:modelValue", coerceEmptyUndefined(value))
  }

  return emit("update:modelValue", value)
}

const id = useId()

const inputEl = ref<HTMLInputElement>()

defineExpose({
  inputEl,
})

const classes = tv({
  slots: {
    prefix: "text-dusk-400 mr-2 ml-3 select-none",
    postfix: "text-ebony-900 mx-3 select-none",
    input:
      "flex-1 bg-transparent py-2 outline-none w-full disabled:cursor-not-allowed rounded-md placeholder-dusk-300 px-3 text-ebony-900",
    container:
      "inline-block w-full transition border-dusk-200 bg-white rounded-md border focus-within:border-forest-600",
  },
  variants: {
    error: {
      true: { container: "border-red-700" },
    },
    disabled: {
      true: {
        container: "bg-dusk-100 opacity-70",
      },
    },
    hasPrefix: {
      true: {
        input: "pl-0",
      },
    },
    isNumeric: {
      true: {
        input: "font-numbers",
      },
    },
  },
  compoundSlots: [
    {
      slots: ["prefix", "postfix"],
      class: "border-red-500 bg-red-100 text-red-900",
      error: true,
    },
  ],
})

const classSlots = computed(() => {
  return classes({
    error: Boolean(props.validationError),
    disabled: props.disabled,
    hasPrefix: Boolean(props.prefixUnit) || Boolean(slots.prefix),
    isNumeric: props.type === "number",
  })
})
</script>

<template>
  <div class="group select-none space-y-1" :class="props.class">
    <div v-if="label || helpText" class="flex items-center gap-2">
      <InputLabel
        v-if="label"
        :for-id="id"
        :label="label"
        :optional="optional"
        :error="validationError != null" />
      <HelpIcon v-if="helpText" :text="$props.helpText" />
    </div>
    <div :class="classSlots.container({ class: $props.containerClass })">
      <div class="flex items-center">
        <slot name="prefix">
          <div
            v-if="prefixUnit"
            :class="classSlots.prefix({ class: prefixClass })">
            {{ prefixUnit }}
          </div>
        </slot>
        <input
          :id="id"
          v-bind="$attrs"
          ref="inputEl"
          :class="classSlots.input({ class: inputClass })"
          :value="modelValue?.trim()"
          :autocomplete="autocomplete || 'off'"
          :disabled="disabled"
          :max="max"
          :min="min"
          :maxlength="maxlength"
          :minlength="minlength"
          :name="name"
          :size="modelValue?.length"
          :required="!optional"
          :type="type"
          :placeholder="placeholder"
          :pattern="pattern"
          :step="step"
          @blur="$forceUpdate"
          @change="
            emitValue(($event?.target as HTMLInputElement)?.value.trim())
          "
          @input="
            eagerUpdate &&
              emitValue(($event?.target as HTMLInputElement)?.value.trim())
          " />
        <slot name="postfix">
          <div
            v-if="postfixUnit"
            :class="classSlots.postfix({ class: postfixClass })">
            {{ postfixUnit }}
          </div>
        </slot>
      </div>
    </div>
    <StatusMessage :error="validationError" status="error" />
    <StatusMessage :error="warningText" status="warning" />
  </div>
</template>
