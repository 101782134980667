<script setup lang="ts">
import { tv } from "tailwind-variants"

const props = defineProps<{
  label?: string
  forId: string
  optional?: boolean
  error?: boolean
}>()

const classes = tv({
  base: "select-none text-ebony-700 font-bold group-focus-within:text-forest-600 transition-colors",
  variants: {
    error: {
      true: "text-red-700",
    },
  },
})

function focusInput() {
  document.getElementById(props.forId)?.focus()
}
</script>

<template>
  <div class="flex flex-col">
    <label v-if="label" :for="forId" :class="classes({ error })">{{
      label
    }}</label>
    <span v-if="optional" class="text-sm text-dusk-400" @click="focusInput"
      >Optional</span
    >
  </div>
</template>
